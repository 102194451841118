try {
    window.$ = window.jQuery = require('jquery');
    window.axios = require('axios');

    window.source = undefined;

    require('./components/Scroll');
    require('./components/Timer');
    require('./components/Jarallax');
    require('./components/Filters');
    require('./components/AnimateNumbers');
} catch (e) {
    console.error(e);
}

$(function () {
    let tabButtons = $('.projects-tabs-container #projects-dropdown-menu .nav-link');
    let dropdownMenu = $('#projects-dropdown-menu');

    tabButtons.on('click', function (e) {
        tabButtons.each(function (el) {
            if (e.currentTarget.id === $(this)[0].id) {
                $(this).attr('aria-expanded', true);
            } else {
                $(this).removeClass('active');
                $(this).attr('aria-expanded', false);
                $(this).attr('aria-selected', false);
            }
        })
    });

    function toggleDropdownVisibility() {
        if (window.innerWidth < 992) {
            dropdownMenu.addClass('dropdown-menu')
        } else {
            dropdownMenu.removeClass('dropdown-menu')
        }
    }

    toggleDropdownVisibility()

    $(window).on('resize', function (e) {
        toggleDropdownVisibility();
    })

    console.log("%cMade with ♥ by Uniqoders - https://uniqoders.com", "color:#403c5e; font-size:36px")
})
