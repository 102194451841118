import CountUp from 'countup';

require('waypoints/lib/noframework.waypoints.js')

$(function () {
    const els = document.getElementsByClassName('data-counter');

    for (let el of els) {
        let endValue = el.getAttribute("data-number");

        new Waypoint({
            element: el,
            handler: function () {
                const options = {
                    separator: '.',
                };
                let countUp = new CountUp(el, 0, endValue, 0, 4, options);
                countUp.start();
                this.destroy();
            },
            offset: 'bottom-in-view',
        })
    }
});