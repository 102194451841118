import * as basicScroll from 'basicscroll'

$(function () {
    let scene_sections = document.querySelectorAll('.scene');

    if (scene_sections.length > 0) {
        for (let scene_section of scene_sections) {
            let _from = scene_section.dataset.from || '0px';
            let _to = scene_section.dataset.to || '100px';


            let scene = basicScroll.create({
                elem: scene_section,
                from: 'top-bottom',
                to: 'bottom-top',
                direct: true,
                props: {
                    '--ty': {
                        from: `${_from}px`,
                        to: `${_to}px`,
                    }
                }
            })

            scene.start();
        }
    }

    let arrowContainer = document.querySelector('.divided-element-container .top-section-container');

    if (!!arrowContainer) {
        let scene = basicScroll.create({
            elem: arrowContainer,
            from: 'top-bottom',
            to: 'bottom-top',
            direct: true,
            props: {
                '--arrow-ty': {
                    from: '0',
                    to: '100%',
                }
            }
        })

        scene.start();
    }

    window.onresize = function () {
        instance.calculate()
        instance.update()
    }
})